import { Navigate, useLocation } from "react-router-dom";
import { UserRoleType } from "./enums/userRole";
import { useSelector } from "react-redux";
import AccessDeniedPage from "../pages/AccessDenied";
import { selectIsAuthenticated, selectUser, selectUserRole } from "../store/authSlice";
import { ROUTES } from "./constants/routePaths";

interface Props {
    children: React.ReactNode;
    path?: string;
    roles: Array<UserRoleType>
  }
  
  export const PrivateRoute = ({ children, roles }: Props) => {
    const location = useLocation();
    const user = useSelector(selectUser);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const userRole = useSelector(selectUserRole);
    const userHasRequiredRole = user && roles.includes(userRole) ? true : false;

    if(isAuthenticated && location.pathname === `/${ROUTES.login}`){
      return <Navigate to={`/${ROUTES.dashboard}`} />
    }
  
    if (isAuthenticated && !userHasRequiredRole) {
      return <AccessDeniedPage />
    }

    if(!isAuthenticated && location.pathname !== `/${ROUTES.login}`) {
      return <Navigate to={`/${ROUTES.login}`} />
    }
  
    return <>{children}</>
  }