import { RouterProvider } from "react-router-dom";
import "./App.css";
import { useEffect, useState } from "react";
import { useGetRouter } from "./hooks/useGetRouter";
import { initState } from "./store/authSlice";
import { useDispatch } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const router = useGetRouter();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    initState(dispatch);
  }, []);

  return (
    loading ? <>Loading</> : 
    <RouterProvider router={router} />
  );
}

export default App;
