import { useSelector } from "react-redux";
import { selectUser } from "../../store/authSlice";
import { useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";

const Header = () => {
  const userStore = useSelector(selectUser);

  const [isDropdownOpen, setDropdownIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setDropdownIsOpen(false),
  });

  return (
    <nav className="flex flex-wrap items-center justify-between px-0 py-2 transition-all duration-250 ease-soft-in rounded-2xl lg:flex-nowrap lg:justify-start sticky top-[1%] backdrop-saturate-[200%] backdrop-blur-[30px] bg-[hsla(0,0%,100%,0.8)] shadow-blur z-110">
      <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
        <nav>
          <ol className="flex flex-wrap pt-1 mr-12 bg-transparent rounded-lg sm:mr-16">
            <li className="leading-normal text-sm">
              <a className="opacity-50 text-slate-700" href="/">
                Dashboard
              </a>
            </li>
            {/* <li
              className="text-sm pl-2 capitalize leading-normal text-slate-700 before:float-left before:pr-2 before:text-gray-600 before:content-['/']"
              aria-current="page"
            >
              Dashboard
            </li> */}
          </ol>
          <h6 className="mb-0 font-bold capitalize">Dashboard</h6>
        </nav>
        <div className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
          <div
            className="flex items-center relative md:ml-auto md:pr-4"
            ref={dropdownRef}
          >
            <button
              onClick={() => setDropdownIsOpen(!isDropdownOpen)}
              className="relative z-10 flex items-center p-2 text-sm text-gray-600 bg-white border border-gray-200 rounded-md focus:border-blue-500 focus:ring-opacity-40 focus:ring-blue-300 focus:ring focus:outline-none"
            >
              <span className="mx-1">{userStore?.user?.email}</span>
              <svg
                className="w-5 h-5 mx-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15.713L18.01 9.70299L16.597 8.28799L12 12.888L7.40399 8.28799L5.98999 9.70199L12 15.713Z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 top-full z-20 w-56 py-2 mt-2 overflow-hidden bg-white rounded-md shadow-xl">
                <a
                  href="/"
                  className="flex items-center p-3 -mt-2 text-sm text-gray-600 transition-colors duration-200 transform hover:bg-gray-100"
                >
                  <img
                    className="flex-shrink-0 object-cover mx-1 rounded-full w-9 h-9"
                    src="/avatar.png"
                    alt="jane avatar"
                  />
                  <div className="mx-1 truncate">
                    <h1 className="text-sm font-semibold text-gray-700 mb-0">
                      Hoş geldin
                    </h1>
                    <p className="text-sm text-gray-500 truncate">
                      {userStore?.user?.email}
                    </p>
                  </div>
                </a>
                <hr className="border-gray-200" />
                <a
                  href="/"
                  className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform hover:bg-gray-100"
                >
                  Profil
                </a>
                <a
                  href="/"
                  className="block px-4 py-3 text-sm text-gray-600 capitalize transition-colors duration-200 transform hover:bg-gray-100"
                >
                  Ayarlar
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
