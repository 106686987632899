import { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Supabase } from '../constants/supabse';
import { UserRoleType } from '../enums/userRole';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../constants/routePaths';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, logoutSuccess, selectIsAuthenticated, selectUser, setRole } from '../../store/authSlice';

const supabaseUrl = Supabase.supabaseUrl;
const supabaseKey = Supabase.supabaseKey;
const supabase = createClient(supabaseUrl, supabaseKey);

export function AuthProvider() {
  const dispatch = useDispatch()
  const userData = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [user, setUser] = useState<any>(userData);
  const [userRole, setUserRole] = useState<UserRoleType | null>(null);

  useEffect(() => {
    if(user && window.location.pathname === `/${ROUTES.login}`) {
      <Navigate to={`/${ROUTES.dashboard}`} />
    }
  },[user])

  const getUserRole = async (userId: string) => {
    const storedRole = localStorage.getItem('role');
    if(storedRole){
      setUserRole(storedRole as any);
    }else{
      const { data, error } = await supabase
        .from('profiles')
        .select('role')
        .eq('id', userId)
        .single();
  
      if (data) {
        localStorage.setItem('role', data.role);
        setUserRole(data.role);
        dispatch(setRole<any>(data.role))
      }
    }

  };

  const login = async (email: string, password: string) => {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;

    dispatch(loginSuccess<any>({
      user: data,
      token: data.session.access_token
    }))
    
    setUser(data.user);
    getUserRole(data.user.id);
    return user;
  };

  const logout = async () => {
    await supabase.auth.signOut();
    dispatch(logoutSuccess());
    localStorage.removeItem('role');
    setUser(null);
    setUserRole(null);
  };

  return {
    login,
    logout
  }
}