import { createSlice, Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { Supabase } from '../shared/constants/supabse';

const initialState = {
  user: null,
  token: null,
  status: 'idle', // idle, loading, succeeded, failed
  error: null,
  isAuthenticated: false,
  role: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state: any, action: any) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.status = 'succeeded';
      state.isAuthenticated = true;
    },
    logoutSuccess: (state: any) => {
      state.user = null;
      state.token = null;
      state.status = 'idle';
      state.role = '';
      state.isAuthenticated = false
    },
    setLoading: (state: any) => {
      state.status = 'loading';
    },
    setRole: (state: any, action: any) => {
        state.role = action.payload;
      },
    setError: (state: any, action: any) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    setStorage: (state: any, action: any) => {
      state.user = action.payload.user;
      state.role = action.payload.role;
      state.isAuthenticated = true;
    },
  },
});

export const initState = (dispatch: Dispatch<UnknownAction>) => {
  const user = JSON.parse(localStorage.getItem(Supabase.storageName) ?? '{}');
  const role = localStorage.getItem('role');
  if(user && role) {
    dispatch(authSlice.actions.setStorage<any>({
      user,
      role
    }));
  }

}

export const { loginSuccess, logoutSuccess, setLoading, setError, setRole } = authSlice.actions;

// Selectors
export const selectUser = (state: any) => state.auth.user;
export const selectUserRole = (state: any) => state.auth.role;
export const selectAuthStatus = (state: any) => state.auth.status;
export const selectAuthToken = (state: any) => state.auth.token;
export const selectAuthError = (state: any) => state.auth.error;
export const selectIsAuthenticated = (state: any) => state.auth.isAuthenticated;

export default authSlice.reducer;
